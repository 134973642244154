// Primary Colors
$color-primary-green: #86bc25;
$color-fn-red: #d23927;
$color-fn-yellow: #f9d649;
$color-bright-green: #70ec48;
$color-white: #ffffff;
$color-pure-black: #000000;

// Secondary Colors
$color-secondary-black: #0a1e40;
$color-navigationmenu-title-font: #75787b;
$color-bright-teal: #7df5c8;
$color-bright-blue: #74edfc;
$color-fn-orange: #e59435;
$color-neon-orange: #e5943531;
$color-pirate-gold: #bc7623;
$color-dark-orange: #6b5e2a;
$color-medium-orange: #f9d64980;
$color-off-white: #fafafa;
$color-grey-0: #f5f5f4;
$color-grey-1: #d0d0ce;
$color-grey-2: #bbbcbc;
$color-grey-3: #a7a8aa;
$color-grey-4: #97999b;
$color-grey-5: #76787b;
$color-grey-6: #64666a;
$color-grey-7: #54565a;
$color-grey-8: #e0e0e0;
$color-grey-9: #646668;
$color-grey-10: rgba(188, 196, 205, 0.4);
$color-grey-11: rgba(188, 196, 205, 0.2);
$color-grey-12: #f2f3f5;
$color-grey-13: #d6e0ea;
$color-grey-14: rgba(188, 196, 205, 0.3);
$color-grey-15: #e1e4e8;
$color-highlighter-purple: #4058d7;
$color-error-message: #7a282b;
$color-success-message: #3e8226;
$color-error-border: #edc4c8;
$color-cyan: #40aad7;
$color-violet: #9540d7;
$color-delete-button: #ea4335;
$color-regent-grey: #8798b0;
$color-scroll-bar: #bcc4cd;
$color-status-deactivate: #a82824;
$color-status-deactivate-2: #eb6262;
$color-status-installing: #ae8433;
$color-barbados-blue: #2460a8;
$color-secondary-purple: #1f00f0;
$color-silver: #bbbbbb;
$color-light-yellow: #d7b6424d;
$color-dark-yellow: #966112;
$color-fn-red-alpha-20: #d2392720;
$color-dark-green: #4e8934;
$color-tulip-tree: #e7b841;

// Background Colors
$color-primary-bg: #edf5de;
$color-secondary-bg: #fdfff9;
$color-primary-content-bg: #e5e5e5;
$color-bg-modal: rgba(0, 0, 0, 0.5);
$color-light-black: rgba(0, 0, 0, 0.1);
$color-error-bg: #f3d8db;
$color-success-bg: #53ae334d;
$color-bg-light-yellow: #f8f7f2;
$color-bg-progress: #f7f7f7;
$color-bg-light-green: rgba(78, 137, 52, 0.1);

// Button Colors
$color-primary-button: #468629;
$color-secondary-button: #62ad41;

// Linear Gradient Colors
$light-gradient: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 78.1%
  ),
  #4e8934;
$white-transparent-gradient: linear-gradient(
  to right,
  rgba(255, 255, 255, 0.8),
  transparent
);
$transparent-white-gradient: linear-gradient(
  to left,
  rgba(255, 255, 255, 0.8),
  transparent
);
$status-active-gradient: linear-gradient(
    0deg,
    rgba(83, 174, 51, 0.3),
    rgba(83, 174, 51, 0.3)
  ),
  $color-white;
$status-installing-gradient: linear-gradient(
    0deg,
    rgba(209, 171, 97, 0.3),
    rgba(209, 171, 97, 0.3)
  ),
  $color-white;
$status-deactivate-gradient: linear-gradient(
    0deg,
    rgba(215, 71, 66, 0.3),
    rgba(215, 71, 66, 0.3)
  ),
  $color-white;
$ticket-tbc-gradient: linear-gradient(
    0deg,
    rgba(31, 0, 240, 0.2),
    rgba(31, 0, 240, 0.2)
  ),
  $color-white;

$blue-linear-gradient: linear-gradient(0deg, #c6dbf3, #c6dbf3), #ffffff;

$dark-shade-linear-gradient: linear-gradient(
    0deg,
    rgba(188, 196, 205, 0.1),
    rgba(188, 196, 205, 0.1)
  ),
  #212326;

$grey-shade-linear-gradient: linear-gradient(0deg, #f2f3f5 0%, #f2f3f5 100%),
  #fff;

$green-gradient-selection-secondary: linear-gradient(
    0deg,
    rgba(70, 134, 41, 0.1) 0%,
    rgba(70, 134, 41, 0.1) 100%
  ),
  #fff;
