@import './mixins';
@import './colors';

$font-family-open-sans: 'Open Sans';

//styleName: Heading 1;
.font-heading-1 {
  @include font(
    $fontSize: 96px,
    $fontWeight: 300,
    $line-height: 131px,
    $letter-spacing: -0.015em
  );
}

//styleName: Heading 2;
.font-heading-2 {
  @include font(
    $fontSize: 60px,
    $fontWeight: 300,
    $line-height: 82px,
    $letter-spacing: -0.005em
  );
}

//styleName: Heading 3;
.font-heading-3 {
  @include font(
    $fontSize: 48px,
    $fontWeight: 400,
    $line-height: 65px,
    $letter-spacing: 0em
  );
}

//styleName: Heading 4;
.font-heading-4 {
  @include font(
    $fontSize: 34px,
    $fontWeight: 400,
    $line-height: 46px,
    $letter-spacing: 0.0025em
  );
}
//styleName: Heading 4 bold;
.font-heading-4-bold {
  @include font(
    $fontSize: 34px,
    $fontWeight: 600,
    $line-height: 46px,
    $letter-spacing: 0.0025em
  );
}

//styleName: Heading 5;
.font-heading-5 {
  @include font(
    $fontSize: 24px,
    $fontWeight: 600,
    $line-height: 33px,
    $letter-spacing: 0em
  );
}

//styleName: Heading Card;
.font-heading-card {
  @include font(
    $fontSize: 24px,
    $fontWeight: 600,
    $line-height: 33px,
    $letter-spacing: 0em
  );
}

//styleName: Heading Card;
.font-graph-total {
  @include font(
    $fontSize: 24px,
    $fontWeight: 600,
    $line-height: 16px,
    $letter-spacing: 0.004em
  );
}

//stylename: Body;
.font-body {
  @include font(
    $fontSize: 16px,
    $fontWeight: 400,
    $line-height: 22px,
    $letter-spacing: 0em
  );
}

//stylename: ;
.font-subHeader {
  @include font(
    $fontSize: 14px,
    $fontWeight: 400,
    $line-height: 19px,
    $letter-spacing: 0em
  );
}

//stylename:font-label ;
.font-label {
  @include font(
    $fontSize: 14px,
    $fontWeight: 400,
    $line-height: 22px,
    $letter-spacing: 0em
  );
}

//styleName: Button;
.font-button {
  @include font(
    $fontSize: 14px,
    $fontWeight: 600,
    $line-height: 19px,
    $letter-spacing: 0.0125em
  );
}

.font-button-small {
  @include font(
    $fontSize: 13px,
    $fontWeight: 400,
    $line-height: 18px,
    $textAlign: 'center'
  );
}

//styleName: Caption;
.font-caption {
  @include font(
    $fontSize: 12px,
    $fontWeight: 400,
    $line-height: 16px,
    $letter-spacing: 0.004em
  );
}

//styleName: Validation error message;
.font-validation-error {
  @include font(
    $fontSize: 12px,
    $fontWeight: 400,
    $line-height: 16px,
    $letter-spacing: 0.004em
  );
  color: $color-fn-red;
}

//styleName: Font Caption bold;
.font-caption-bold {
  @include font(
    $fontSize: 12px,
    $fontWeight: 600,
    $line-height: 16px,
    $letter-spacing: 0.004em
  );
}

//styleName: Overline;
.font-overline {
  @include font(
    $fontSize: 12px,
    $fontWeight: 400,
    $line-height: 16px,
    $letter-spacing: 0.015em
  );
}

//styleName: progress-bar;
.font-progress-bar,
.font-small-bold {
  @include font($fontSize: 10px, $fontWeight: 600, $line-height: 13px);
}

.font-small {
  @include font($fontSize: 10px, $fontWeight: 400, $line-height: 13px);
}

.font-subHeader-small {
  @include font($fontSize: 10px, $fontWeight: 400, $line-height: 14px);
}

.font-comparison-label {
  @include font(
    $fontWeight: 400,
    $fontSize: 10px,
    $line-height: 16px,
    $letter-spacing: 0.004em
  );
}

.font-tooltip-small {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: $color-grey-5;
}

.modal-heading {
  @include font($fontSize: 18px, $fontWeight: 600, $line-height: 25px);
}

.table-typography {
  @include font($fontSize: 14px, $fontWeight: 400, $line-height: 19px);
}

.provider-list-typography {
  @include font($fontSize: 20px, $fontWeight: 600, $line-height: 27px);
}

.graph-heading-typography,
.form-header {
  @include font($fontSize: 16px, $fontWeight: 600, $line-height: 22px);
}

.selected-dropdown-option {
  @include font(
    $fontSize: 14px,
    $fontWeight: 600,
    $line-height: 19px,
    $color: var(--button-icon-color)
  );
}

//styleName: Font Link bold;
.font-link-bold {
  @include font(
    $fontSize: 12px,
    $fontWeight: 600,
    $line-height: 22px,
    $color: $color-primary-button
  );
}

.small-grey-button {
  @include font(
    $fontSize: 12px,
    $fontWeight: 400,
    $line-height: 16px,
    $color: $color-grey-4
  );
}

.font-annotation-light {
  @include font($fontSize: 11px, $fontWeight: 400, $line-height: 16px);
}

.font-annotation-bold {
  @include font($fontSize: 11px, $fontWeight: 600, $line-height: 16px);
}

.font-extra-large-bolder {
  @include font($fontSize: 22px, $fontWeight: 700, $line-height: 30px);
}
